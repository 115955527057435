@import "variables";
@import "overrides";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

button {
  outline: none;
  border: none;
  cursor: pointer;
  user-select: none;
}

input {
  border: none;
  outline: none;
  user-select: none;
}

fieldset {
  border: none;
  outline: none;
}

body {
  width: 100%;
  min-height: 100vh;

  font-family: 'Open Sans', sans-serif;
  color: var(--color-text-main);
  background: url("../assets/background.png");
  background-size: cover;
}

.primary-btn {
  color: var(--color-text-main);
  text-transform: uppercase;
  display: block;
  text-align: center;

  padding: 20px 87px;

  font-size:  18px;
  font-weight: var(--font-weight-bold);
  font-family: 'Roboto Condensed', sans-serif;

  background: var(--gradient-blue);

  border-radius: var(--border-radius-small);

  transition: 0.2s all ease;

  &:hover {
   transform: scale(1.03);
  }
}

