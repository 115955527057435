
.header {
  width: 100%;
  max-width: calc( 1140px + var(--indent) * 2);
  padding: 0 var(--indent);

  margin: 0 auto;

  display: flex;
  justify-content: space-between;
  align-items: center;


  .logoWrap {
    display: flex;
    gap: 24px;
    align-items: center;

    .logo {
      width: 107px;
      height: 60px;
    }

    .logoText {
      font-size: 12px;
      color: var(--color-text-secondary);
    }
  }

  .signInBtn {
    display: flex;
    align-items: center;
    gap: 10px;

    display: none;

    padding: 18px 40px;

    font-size: 12px;
    color: var(--color-text-main);

    background:
            linear-gradient(#1F201FFF,#1F201FFF) padding-box,
            var(--gradient-blue) border-box;
    border: 2px solid transparent;
    border-radius: 5px;

    transition: 0.2s all ease;

    &:hover {
      transform: scale(1.02);
      box-shadow: 0px 3px 15px 0px #0FDAEA;
    }
  }

  .languageWrap {
    display: flex;
    align-items: start;
    gap: 8px;

    .languageMainBtn {
      background: none;

      padding: 6px 15px;
      border-radius: 5px;
      border: 1px solid #fff;

      color: var(--color-text-main);
      text-transform: uppercase;
      font-size: 16px;
      font-weight: var(--font-weight-bold);
    }

    .languageSecondaryBtn {
      background: none;

      color: rgba(255, 255, 255, 0.23);
      font-size: 14px;
      text-transform: uppercase;
      font-weight: var(--font-weight-bold);
    }
  }

  .contacts {
    .phone {
      font-family: 'Ubuntu', sans-serif;
      font-weight: var(--font-weight-bold);
      font-size: 20px;

      color: var(--color-text-main);
      text-decoration: none;
    }

    .schedule {
      display: block;
      color: var(--color-text-main);
      text-decoration: none;
      font-size: 16px;
      position: relative;
      margin-left: auto;
      text-align: right;

      //&::before {
      //  position: absolute;
      //  width: 8px;
      //  height: 8px;
      //  content: "";
      //  background: url("../../assets/marker.png");
      //
      //  top: 4.5px;
      //  left: -11px;
      //}
    }
  }
}

@media screen and (max-width: 950px) {
  .signInBtn {
    display: none!important;
  }
}

@media screen and (max-width: 750px) {
  .logoText {
    display: none;
  }

  .logo {
    width: 71px!important;
    height: 40px!important;
  }
}

//@media screen and (max-width: 500px) {
//  .contacts {
//    display: none;
//  }
//}
