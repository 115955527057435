
.navigation {

  padding: 40px 0 0 40px;

  .menu {

    display: flex;
    gap: 85px;
    justify-content: center;
    user-select: none;

    li {
      font-family: 'Ubuntu', sans-serif;
      color: var(--color-text-main);
      font-size: 15px;
      list-style: none;

      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .line {
    max-width: 1140px;
    margin: 40px auto 0 auto;

    border: none;

    height: 1px;
    background: linear-gradient(90deg,
      rgba(255, 22, 127, 0),
      rgba(255, 22, 127, 0.5),
      rgba(255, 22, 127, 0));
  }
}

@media screen and (max-width: 950px) {
  .menu {
    gap: 50px!important;
  }
}

@media screen and (max-width: 700px) {
  .navigation {
    display: none;
  }
}
