
.bulletsWrap {
  display: flex;
  justify-content: center;
  align-items: end;
  gap: 100px;
  flex-wrap: wrap;

  width: 100%;
  max-width: calc( 1140px + var(--indent) * 2);
  padding: 0 var(--indent);

  margin: 0 auto;

  padding-top: var(--indent);

  .bullet {

    .bulletHeader {
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 40px;
      color: var(--color-text-main);
      font-weight: 700;

      span {
        background: var(--color-pink);
        padding: 0 .3rem;
        border-radius: var(--border-radius-small);
      }
    }

    .bulletText {
      margin-top: 6px;
      font-size: 14px;
      color: var(--color-text-secondary);
    }
  }
}

@media screen and (max-width: 900px) {
  .bulletHeader,
  .bulletText {
    text-align: center;
  }

  .bulletsWrap {
    gap: var(--indent);
  }
}
