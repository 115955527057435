:root {
  // Colors
  --color-text-main: #fff;
  --color-text-secondary: rgba(255, 255, 255, 0.6);
  --color-pink: #FF167FFF;
  --color-black-background: #1B1C23;
  --color-placeholder: #797980;
  --color-close-btn: #797980;

  // Gradient
  --gradient-blue: linear-gradient(267.04deg, #0FDAEA -6.79%, #21A1CA 107.45%);

  // Borders
  --border-radius-small: 5px;

  // Indents
  --indent: 50px;

  // Fonts [DEPRECATED]
  --font-weight-bold: 700;
}
