
.main {
  display: flex;

  width: 100%;
  max-width: calc( 1140px + var(--indent) * 2);
  padding: 120px var(--indent) 0 var(--indent);

  margin: 0 auto;

  //background: url("/assets/stadium.png") no-repeat center right;
  //background-size: contain;


  .info {
    width: 100%;
  }

  .mainHeader {
    max-width: 635px;

    font-size: 40px;
    color: var(--color-text-main);
    font-weight: var(--font-weight-bold);
    line-height: 64px;
    text-transform: uppercase;
    font-family: 'Roboto Condensed', sans-serif;

    .greenBg {
      background: linear-gradient(88.78deg, rgba(6, 97, 104, 0.35) 0%, rgba(0, 99, 107, 0.35) 100%);
      border-radius: var(--border-radius-small);

      padding: 0 .32rem;
    }

    .pinkColor {
      color: var(--color-pink)
    }
  }

  .actions {
    margin-top: 30px;

    display: flex;
    gap: 50px;

    .accessBtnWrap {
      padding-top: 15px;
    }

    a {
      text-decoration: none;
    }

    .getAccessBtn {
      color: var(--color-text-main);
      text-transform: uppercase;
      display: block;
      text-align: center;
      text-decoration: none;

      padding: 20px 87px;

      font-size:  18px;
      font-weight: var(--font-weight-bold);
      font-family: 'Roboto Condensed', sans-serif;

      background: var(--gradient-blue);

      border-radius: var(--border-radius-small);

      transition: 0.2s all ease;

      &:hover {
        transform: scale(1.03);
      }
    }

    .accessInfo {
      margin-top: 24px;
      max-width: 300px;

      color: var(--color-text-main);
      font-size: 14px;

      span {
        font-weight: 700;
      }
    }

    .videoBtnWrap {
      display: flex;
      width: 275px;
      height: 130px;
      user-select: none;

      .videoPlayBtn {
        width: 122px;
        height: 122px;
        cursor: pointer;

        transition: 0.2s all ease;

        &:hover {
          transform: scale(1.04);
        }
      }

      .videoInfo {
        margin-top: auto;
        font-size: 12px;
        width: 145px;
        position: relative;

        span {
          font-weight: var(--font-weight-bold);
        }

        &::after {
          position: absolute;
          content: "";

          width: 185px;
          height: 43px;

          background: url("../../assets/pointer.svg") no-repeat bottom left;

          left: -55px;
          bottom: -18px;
        }
      }

    }
  }
}

.mainImage {
  position: absolute;
  left: 60%;
  top: 30%;
  z-index: -1;

  width: 561px;
  height: 497px;

  transform: scale(1.1);
}

.mobileOnly {
  display: none;
}

@media screen and (max-width: 1200px) {
  .mainImage {
    display: none;
  }

  .main {
    padding-top: 50px !important;
  }
}

@media screen and (max-width: 900px) {
  .mainHeader {
    font-size: 24px!important;
    line-height: 37px!important;
    text-align: center;
  }

  .actions {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .getAccessBtn {
    font-size: 14px!important;
    padding: 20px 40px!important;
    width: 100%;
  }

  .accessInfo {
    font-size: 12px;
  }
}
