.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 100;
  background: var(--color-black-background);

  display: flex;
  justify-content: center;
  align-items: center;

  .closeBtn {
    top: 20px;
    right: 20px;
    position: fixed;
    background: none;
    color: var(--color-close-btn);
    display: block;
    width: 40px;
    height: 40px;

    svg {
      width: 40px;
      height: 40px;
    }

    &:hover {
      color: #696969;
    }
  }

  .form {
    width: 330px;

    legend {
      font-size: 36px;
      font-family: 'Roboto Condensed', sans-serif;
      text-transform: uppercase;
    }

    .subLegend {
      margin-top: 13px;
      margin-bottom: 50px;
      line-height: 27px;
    }

    fieldset {
      display: flex;
      flex-direction: column;
      gap: 40px;

      .input {
        label {
          color: var(--color-placeholder);
        }
        input {
          color: var(--color-text-main);
        }
      }
    }

    .submitBtn {
      height: 70px;
      width: 100%;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 42px;
    }
  }
}
