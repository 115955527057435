

.container {
    height: 100vh;
    max-height: 1080px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;


    padding-top: 50px;
    padding-bottom: 60px;
}

@media screen and (max-width: 600px) {
    .container {
        max-height: unset;
    }
}

